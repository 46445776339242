import Vue from "vue";
import VueRouter from "vue-router";
// 引入进度条模块
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import cookies from "vue-cookies";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    component: () => import("../views/index.vue"),
    redirect: "/classification",
    // redirect: '/home',
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index.vue"),
      },
      {
        path: "/create",
        name: "create",
        component: () => import("../views/create/index.vue"),
      },
      {
        path: "/create/title",
        name: "title",
        component: () => import("../views/create/title.vue"),
      },
      {
        path: "/user/info",
        name: "userinfo",
        component: () => import("../views/user/info.vue"),
      },
      {
        path: "/weChatInvitation",
        name: "weChatInvitation",
        component: () => import("../views/help/weChatInvitation.vue"),
      },
      {
        path: "/classification",
        name: "classification",
        component: () => import("../views/classification/index.vue"),
      },
    ],
  },
  {
    path: "/exam",
    name: "exam",
    component: () => import("../views/exam/index.vue"),
    children: [
      {
        path: "/exams",
        name: "exams",
        component: () => import("../views/exam/exams.vue"),
      },
      {
        path: "/examsType",
        name: "examsType",
        component: () => import("../views/exam/examsType.vue"),
      },
      {
        path: "/kaoshi",
        name: "kaoshi",
        component: () => import("../views/exam/kaoshi.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () => import("../views/questionnaire/index.vue"),
    children: [
      {
        path: "/design/designQuestionnaire",
        name: "designQuestionnaire",
        component: () => import("../views/design/designQuestionnaire.vue"),
      },
      {
        path: "/design/editQuestionnaire",
        name: "editQuestionnaire",
        component: () => import("../views/design/editQuestionnaire.vue"),
      },
      {
        path: "/design/questionnaireSettings",
        name: "questionnaireSettings",
        component: () => import("../views/design/questionnaireSettings.vue"),
      },
      {
        path: "/design/questionnaireAppearance",
        name: "questionnaireAppearance",
        component: () => import("../views/design/questionnaireAppearance.vue"),
      },
      {
        path: "/design/qualityControl",
        name: "qualityControl",
        component: () => import("../views/design/qualityControl.vue"),
      },
      {
        path: "/sending/linkQRCode",
        name: "linkQRCode",
        component: () => import("../views/sending/linkQRCode.vue"),
      },
      {
        path: "/sending/weChatSending",
        name: "weChatSending",
        component: () => import("../views/sending/weChatSending.vue"),
      },
      {
        path: "/sending/emailSending",
        name: "emailSending",
        component: () => import("../views/sending/emailSending.vue"),
      },
      {
        path: "/sending/shortMessageService",
        name: "shortMessageService",
        component: () => import("../views/sending/shortMessageService.vue"),
      },
      {
        path: "/analysis/statisticAnalysis",
        name: "statisticAnalysis",
        component: () => import("../views/analysis/statisticAnalysis.vue"),
      },
      {
        path: "/analysis/viewAndDownloadTheAnswerSheet",
        name: "viewAndDownloadTheAnswerSheet",
        component: () =>
          import("../views/analysis/viewAndDownloadTheAnswerSheet.vue"),
      },
      {
        path: "/analysis/sourceAnalysis",
        name: "sourceAnalysis",
        component: () => import("../views/analysis/sourceAnalysis.vue"),
      },
      {
        path: "/analysis/dataScreen",
        name: "dataScreen",
        component: () => import("../views/analysis/dataScreen.vue"),
      },
    ],
  },
  {
    path: "/quanping",
    name: "quanping",
    component: () => import("../views/analysis/quanping.vue"),
  },
  {
    path: "/create/kaoshi",
    name: "info",
    component: () => import("../views/create/info.vue"),
  },
  {
    path: "/questionnaires",
    name: "questionnaires",
    component: () => import("../views/questionnaire/kaoshi.vue"),
    children: [
      {
        path: "/design/editingExams",
        name: "editingExams",
        component: () => import("../views/design/editingExams.vue"),
      },
      {
        path: "/design/examDesign",
        name: "examDesign",
        component: () => import("../views/design/examDesign.vue"),
      },
      {
        path: "/design/examPreview",
        name: "examPreview",
        component: () => import("../views/design/examPreview.vue"),
      },
      {
        path: "/sending/QRCode",
        name: "QRCode",
        component: () => import("../views/sending/QRCode.vue"),
      },
      {
        path: "/sending/weChatSendings",
        name: "weChatSendings",
        component: () => import("../views/sending/weChatSendings.vue"),
      },
      {
        path: "/analysis/dataScreen",
        name: "dataScreen",
        component: () => import("../views/analysis/dataScreen.vue"),
      },
      {
        path: "/analysis/downloadGrades",
        name: "downloadGrades",
        component: () => import("../views/analysis/downloadGrades.vue"),
      },
      {
        path: "/analysis/examRanking",
        name: "examRanking",
        component: () => import("../views/analysis/examRanking.vue"),
      },
    ],
  },
  {
    path: "/preview/index",
    name: "preview",
    component: () => import("../views/preview/index.vue"),
  },
  {
    path: "/questionnaire/raffle",
    name: "questionnaireRaffle",
    component: () => import("../views/questionnaire/raffle.vue"),
    children: [
      {
        path: "/raffle/prize",
        name: "prize",
        component: () => import("../views/raffle/prize.vue"),
      },
      {
        path: "/raffle/record",
        name: "record",
        component: () => import("../views/raffle/record.vue"),
      },
      {
        path: "/raffle/turntable",
        name: "turntable",
        component: () => import("../views/raffle/turntable.vue"),
      },
      {
        path: "/raffle/configuration",
        name: "configuration",
        component: () => import("../views/raffle/configuration.vue"),
      },
      {
        path: "/raffle/niudan",
        name: "nine",
        component: () => import("../views/raffle/niudan.vue"),
      },
      {
        path: "/raffle/nine",
        name: "nine",
        component: () => import("../views/raffle/nine.vue"),
      },
      {
        path: "/raffle/smash",
        name: "smash",
        component: () => import("../views/raffle/smash.vue"),
      },
      {
        path: "/raffle/ffka",
        name: "ffka",
        component: () => import("../views/raffle/ffka.vue"),
      },
      {
        path: "/raffle/theBox",
        name: "theBox",
        component: () => import("../views/raffle/theBox.vue"),
      },
      {
        path: "/raffle/bigping",
        name: "bigping",
        component: () => import("../views/raffle/bigping.vue"),
      },
      {
        path: "/raffle/hudong",
        name: "nine",
        component: () => import("../views/raffle/hudong.vue"),
      },
      {
        path: "/raffle/juxing",
        name: "juxing",
        component: () => import("../views/raffle/juxing.vue"),
      },
      {
        path: "/raffle/invit",
        name: "invit",
        component: () => import("../views/raffle/invit.vue"),
      },
      {
        path: "/raffle/red",
        name: "red",
        component: () => import("../views/raffle/red.vue"),
      },
      {
        path: "/raffle/redWall",
        name: "redWall",
        component: () => import("../views/raffle/redWall.vue"),
      },
      {
        path: "/raffle/shiyong",
        name: "shiyong",
        component: () => import("../views/raffle/shiyong.vue"),
      },
      {
        path: "/raffle/bobing",
        name: "bobing",
        component: () => import("../views/raffle/bobing.vue"),
      },
    ],
  },
  {
    path: "/questionnaire/hudong",
    name: "questionnaireHudong",
    component: () => import("../views/questionnaire/hudong.vue"),
    children: [
      {
        path: "/hudong/index",
        name: "index",
        component: () => import("../views/hudong/index.vue"),
      },
      {
        path: "/hudong/vote",
        name: "vote",
        component: () => import("../views/hudong/vote.vue"),
      },
      {
        path: "/hudong/editPrize",
        name: "editPrize",
        component: () => import("../views/hudong/editPrize.vue"),
      },
      {
        path: "/hudong/edit",
        name: "edit",
        component: () => import("../views/hudong/edit.vue"),
      },
      {
        path: "/hudong/cutDried",
        name: "cutDried",
        component: () => import("../views/hudong/cutDried.vue"),
      },
      {
        path: "/hudong/bulletComments",
        name: "bulletComments",
        component: () => import("../views/hudong/bulletComments.vue"),
      },
      {
        path: "/hudong/hudong",
        name: "hudong",
        component: () => import("../views/hudong/hudong.vue"),
      },
      {
        path: "/hudong/prizeManage",
        name: "prizeManage",
        component: () => import("../views/hudong/prizeManage.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  base: "/yxwz/",
  mode: "hash",
  routes,
});
// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 开始加载界面进度条
  nprogress.start();
  // 判断当前访问的页面
  if (to.path !== "/login") {
    // 判断当前用户状态是否登录
    // if (localStorage.getItem('token')) {
    if (cookies.get("token")) {
      return next();
    } else {
      return next("/login");
    }
  }
  return next();
});
router.afterEach(() => {
  nprogress.done();
});
export default router;
